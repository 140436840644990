<template>
    <div>
        {{code}}
        {{state}}
    </div>
</template>
<script>
export default {
name: 'WeixinInfo',
data(){
    return{
        code:'',
        state:'',
    }
  },
  mounted(){    
    this.code=this.$route.query.code
    this.state=this.$route.query.state
    this.$axios.get('/apis/api/weixin/weixincode/?code='+this.code+'&state='+this.state)
      .then(function (response) {
          var data=response.data
          if (data.code==200){
            console.log(data)
          }else{
            // getData()
          }
      })
      .catch(function (error) {
        // getData()
      });
 }
}


</script>