<script>
export default {
  name: "lemonMessageVideo",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-voice"
        props={{ ...this.$attrs }}
        scopedSlots={{ 
          content: props => {
            {/* const content = this.IMUI.emojiNameToImage(props.content); */}
            return <div   style='width:300px;'>
              <div id={props.id} style='width:300px;height:200px;background-color:black'></div>
            <div id={'fj_'+props.id} style='width:100%;white-space:normal !important'>
            </div>
            </div>
            ;
          }
        }}
      />
    );
  },
  mounted(){
    var message=this.$attrs.message
    var content=message.content
    
    var videoObject = {
            container: '#'+message.id,//“#”代表容器的ID，“.”或“”代表容器的class,
            plug:'hls.js',//设置使用hls插件
            controls:false,
            m3u8autoLoad:false,
            timeScheduleAdjust:0,
            // barHideTime:999990,
            video:content[0]
    };

    function showlist(){
      var tag=0
      var html=''
      for (var i=0;i<content.length;i++){
        if (i%3==0){
          // console.log(i)
          tag=i
          html='<div class="el-row" id="el_row_'+message.id+"_"+i+'">\
              <div class="el-col el-col-8" style="white-space:normal !important;margin-bottom:10px">\
                <button data="'+message.content[i]+'" style="width:90%" type="button" class="el-button el-button--primary el-button--mini" id="'+message.id+"_"+i+'"><!----><!----><span>第'+numberToChinese(i+1)+'集</span></button>\
              </div>\
            </div>'
          $('#fj_'+message.id).append(html)
          $('#'+message.id+'_'+i).on('click',function(e){
            var m3u8url=$(this).attr('data')
            layer.open({
                      title:false,
                      // shadeClose:true,
                      type:1,
                      content: '<div id="video" style="width:100%;height:100%;"></div>',
                      area:['500px', '300px'],
                      success:function(){
                        var videoObject = {
                                container: '#video',//“#”代表容器的ID，“.”或“”代表容器的class,
                                autoplay:true,
                                plug:'hls.js',//设置使用hls插件
                                controls:false,
                                // barHideTime:999990,
                                video:m3u8url
                        };
                        this.videolayer=new ckplayer(videoObject );
                      },
                      cancel:function(){
                        this.videolayer.remove()
                      },
                      end:function(){
                      }
                    });
                return false;
              })
        }else{
           console.log(i)
           html='<div class="el-col el-col-8" style="white-space:normal !important">\
                <button data="'+message.content[i]+'" style="width:90%" type="button" class="el-button el-button--primary el-button--mini" id="'+message.id+"_"+i+'"><!----><!----><span>第'+numberToChinese(i+1)+'集</span></button>\
              </div>'
          $('#el_row_'+message.id+'_'+tag.toString()).append(html)
          $('#'+message.id+'_'+i).on('click',function(e){
            var m3u8url=$(this).attr('data')
            layer.open({
                      title:false,
                      // shadeClose:true,
                      type:1,
                      content: '<div id="video" style="width:100%;height:100%;"></div>',
                      area:['500px', '300px'],
                      success:function(){
                        var videoObject = {
                                container: '#video',//“#”代表容器的ID，“.”或“”代表容器的class,
                                autoplay:true,
                                plug:'hls.js',//设置使用hls插件
                                controls:false,
                                // barHideTime:999990,
                                video:m3u8url
                        };
                        this.videolayer=new ckplayer(videoObject );
                      },
                      cancel:function(){
                        this.videolayer.remove()
                      },
                      end:function(){
                      }
                    });
                return false;
              })
        }
      
      // $('#'+message.id+'_'+i).on('click',(function(){
      //   musictag=parseInt(this.id.split('_')[1])
      //   $('#musiclist_'+message.id).find('img').removeClass()
      //   $('#'+message.id+'_'+musictag).find('img').addClass('rotating_element')
      //   play()
      // }))
    }
    }

    var player=new ckplayer(videoObject );
    player.addListener('play',function(){
      setTimeout(() => {
        player.pause()
      }, 1000);
      player.stopm3u8()
    })
   
    player.bar(false)
    if (content.length>1){
      showlist()
    }
    


    function numberToChinese(num) {
      const chineseNumArr = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      const chineseUnitArr = ['', '十', '百', '千', '万', '亿'];
    
      let result = '';
      let unitIndex = 0;
    
      if (num === 0) {
        result = chineseNumArr[0];
      }
    
      while (num > 0) {
        let temp = num % 10;
        if (temp === 0) {
          if (result[0] !== chineseNumArr[0]) {
            result = chineseNumArr[0] + result;
          }
        } else {
          result = chineseNumArr[temp] + chineseUnitArr[unitIndex] + result;
        }
    
        unitIndex++;
        num = Math.floor(num / 10);
      }
    
      return result;
    }

    // player.remove()
  }
};
</script>
<style lang="stylus">

</style> 
