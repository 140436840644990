import Vue from 'vue'
import VueRouter from 'vue-router'
import CustomerServer from '../views/CustomerServer.vue'
import Customer from '../views/Customer.vue'
import WeixinInfo from '../views/WeixinInfo.vue'
import VideoPlayer from '@/views/VideoPlayer.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'WeixinInfo',
  //   component: WeixinInfo
  // },
  {
    path: '/customer/:siteid',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/customerserver/:siteid',
    name: 'customerserver',
    component: CustomerServer
  },
  {
    path: '/videoplayer/',
    name: 'VideoPlayer',
    component: VideoPlayer
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
