<script>
export default {
  name: "lemonMessageIframe",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-voice"
        props={{ ...this.$attrs }}
        scopedSlots={{ 
          content: props => [
            <div class="lemon-message-file__inner">
              <p class="lemon-message-file__name">{props.fileName}</p>
              <p class="lemon-message-file__byte">
              </p>
            </div>,
            <div class="lemon-message-file__sfx">
              <i class="lemon-icon-attah" />
            </div>
          ]
        }}
      />
    );
  },
  mounted(){
    
  }
};
</script>
<style lang="stylus">

</style> 
