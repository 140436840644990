<template>
    <div id="video" :style="style"></div>
</template>
<script>
export default {
name: 'VideoPlayer',
data(){
    return{
        m3u8:'',
        videolayer:null,
        screenHeight:'',
        screenWidth:'',
        style:''
    }
  },
  mounted(){    
    this.m3u8=this.$route.query.m3u8  //获取m3u8
    
    console.log(this.m3u8)
    var videoObject = {
            container: '#video',//“#”代表容器的ID，“.”或“”代表容器的class,
            autoplay:true,
            ptag:true,
            plug:'hls.js',//设置使用hls插件
            controls:false,
            // barHideTime:999990,
            video:this.m3u8
    };
    this.videolayer=new ckplayer(videoObject);
    console.log(this.videolayer)
    this.screenHeight=window.innerHeight+'px'
    this.screenWidth=window.innerWidth+'px'
    this.style='width: 100%;height:'+this.screenHeight
 }
}


</script>