import Vue from 'vue'
import App from './App.vue'
import router from './router'
import lemonImui from 'lemon-imui'
import 'lemon-imui/dist/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'


Vue.use(ElementUI);
Vue.use(lemonImui);
Vue.config.productionTip = false
Vue.prototype.$axios=axios;
// Vue.prototype.$wshost='ws://127.0.0.1:8088'
Vue.prototype.$wshost='wss://www.ogoai.com/wss'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
