<template>
    <div>
        <el-dialog
            title="请选择你希望转成的格式"
            :visible='geshiVisible'
            :before-close="handleClose"
            width="50%">
            <el-select v-model="geshivalue" placeholder="请选择">
                <el-option 
                v-for="item in geshioptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <el-button  type="success" @click="geshiwork">确认</el-button>
             <!-- {{message}} -->
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'FormatChange',
    props:{'message':{
      type: Boolean,
      default: true
    },'userid':{
      type:String
    }},
    computed:{
        geshiVisible:{
            get(){
                return this.message
            },
            set(newValue){
                this.$emit('update:value',newValue)
            }
        }
    },
    data(){
        return {
        geshitag:false,
        // geshiVisible:true,
        geshivalue:'pdf',
        geshioptions: [
            {
            value: 'pdf',
            label: 'PDF',
            },
            {
            value: 'docx',
            label: 'WORD',
            },
            {
            value: 'ppt',
            label: 'PPT',
            },
            {
            value: 'xlsx',
            label: 'EXCEL',
            },
            {
            value: 'png',
            label: 'PNG',
            },
            {
            value: 'jpg',
            label: 'JPG',
            },
            {
            value: 'bmp',
            label: 'BMP',
            },
            {
            value: 'tiff',
            label: 'TIFF',
            },
            {
            value: 'epub',
            label: 'EPUB',
            },
            {
            value: 'mobi',
            label: 'MOBI',
            },
            {
            value: 'pages',
            label: 'pages',
            },
            {
            value: 'numbers',
            label: 'numbers',
            },
        ],
            }
        },
        methods:{
            getCookie (name) {
              var value = '; ' + document.cookie
              var parts = value.split('; ' + name + '=')
              if (parts.length === 2) return parts.pop().split(';').shift()
            },
             handleClose(done) {
               this.$emit('close-event');
            },
            geshiwork(){
                var _that=this
                this.$axios({
                    method:'post',
                    headers:{'X-CSRFToken':_that.getCookie('csrftoken')},
                    url:'/apis/api/v1/geshiupload/',
                    data:{fakeid:this.userid,_type:this.geshivalue}
                }).then(function (response) {
                        var data=response.data
                        console.log(data)
                        _that.$emit('child-event',_that.geshivalue)
                    })
                    .catch(function (error) {
                    console.log(error)
                    });
            },
            
        }
}
</script>