<template>
  <div class="home">
    <el-image id="elimg"
      style="width: 100px; height: 100px;display: none;"
      :src="url" 
      :preview-src-list="srcList">
    </el-image>
    <lemon-imui 
      ref="IMUI"
      :theme="theme"
      :user="user"
      :width=lemonui_width
      :height=lemonui_height
      @change-contact	='ChangeContact'
      @pull-messages='handlePullMessages'
      @send="handleSend"
      @message-click="messageclick"
      :sendKey="sendKey"
    >
    <template #message-title="concact">
      <div style="float: right;">
        <el-switch
          v-model="concact.listen"
          @change="changeaiback(concact)"
          active-text="智能回复开启"
          inactive-text="智能回复关闭">
        </el-switch>
      </div>
    </template>
    <template #sidebar-message="concact">
        <span class="lemon-badge lemon-contact__avatar">
          <span class="lemon-avatar" style="width: 40px; height: 40px; line-height: 40px; font-size: 20px;">
            <div class="image-with-online" v-if="concact.online==true">
              <img :src="concact.avatar">
            </div>
            <div class="image-with-offline" v-else>
              <img :src="concact.avatar">
            </div>
          </span>
          
          <span class='lemon-badge__label' v-if="concact.unread>0">{{concact.unread  }}</span>
        </span>
        <div class="lemon-contact__inner">
          <p class="lemon-contact__label">
            <span class="lemon-contact__name">{{ concact.displayName }}</span>
            <span class="lemon-contact__time">{{ changetime(concact.lastSendTime) }}</span>
          </p>
          <p class="lemon-contact__content">
            <span>{{ concact.lastContent }}</span>
          </p>
        </div>
    </template>
    <template #sidebar-contact="concact">
        <span class="lemon-badge lemon-contact__avatar">
          <span class="lemon-avatar" style="width: 40px; height: 40px; line-height: 40px; font-size: 20px;">
            <div class="image-with-online" v-if="concact.online==true">
              <img :src="concact.avatar">
            </div>
            <div class="image-with-offline" v-else>
              <img :src="concact.avatar">
            </div>
            
          </span>
        </span>
          <div class="lemon-contact__inner">
            <p class="lemon-contact__label">
              <span class="lemon-contact__name">{{ concact.displayName }}</span>
            </p>
          </div>   
    </template>
    <template #editor-footer>
          <span>使用 Enter 键可进行消息发送</span>
    </template>
    <template #message-after="message">
        <el-button type="text"  v-if="message.type=='file'" @click="downfile(message)">下载</el-button>
    </template>
  </lemon-imui>
  </div>
</template>

<script>
import Vue from "vue";
import LemonMessageText from "../components/lemon-message-text.vue";
import LemonMessageIframe from "../components/lemon-message-iframe.vue";
import LemonMessageVideo from "../components/lemon-message-video.vue";
import LemonMessageVoice from "../components/lemon-message-voice.vue";
Vue.component(LemonMessageText.name, LemonMessageText);
Vue.component(LemonMessageIframe.name, LemonMessageIframe);
Vue.component(LemonMessageVideo.name, LemonMessageVideo);
Vue.component(LemonMessageVoice.name, LemonMessageVoice);
export default {
  name: 'CoustomerServer',
  components: {
    
  },
  data(){
    return{
      theme:'blue',
      siteid:'',
      token:'',
      socketurl:'',
      user:{},
      webs:'',
      userid:'123',
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
      ],
      elimg:null,
      lemonui_width:window.innerWidth,
      lemonui_height:window.innerHeight,
      concactids:[],
      contacts:[],
      contactspage:{},
      page:1,
    }
  },
  mounted(){
    const { IMUI } = this.$refs;
    var _that=this
    // 站点ID
    this.siteid=this.$route.params.siteid
    this.token=this.$route.query.token
    // 站点链接
    this.socketurl=this.$wshost+'/im_server/'+this.siteid+'/'
    // 获取站点信息
    this.getsiteinfo()
    //获取联系人信息
    _that.getcontacts(IMUI)
    setInterval(function(){
      _that.changecontacts(IMUI)
    },15000)
    
    // 初始化websocket链接
    this.initWebsocket()
    
    var _this = this
    window.onresize = () => {
            return (() => {
              _this.lemonui_width=window.innerWidth
              _this.lemonui_height=window.innerHeight
              _this.$forceUpdate();
            })()
          }
  },
  methods:{
    // 切换ai回复状态
    changeaiback(concact){
      this.$axios({
          url:'/apis/api/webchat/setsiteuser/',
          method:'post',
          data:{userid:concact.id,listen:concact.listen,tag:concact.tag},
          headers:{Authorization:this.token || ''},
      })
        .then(function (response) {
          
        })
        .catch(function (error) {
          console.log('getcontacts_error')
        });
    },
    // 时间戳转时间
    changetime(timestamp){
      const date = new Date(timestamp);
      const today=new Date();
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      if (date.getDate()===today.getDate()&&date.getMonth()===today.getMonth()&&date.getFullYear()===today.getFullYear()){
        return hours + ':' + minutes
      }else{
        return  year + '/' + month + '/' + day
      }
    },
    // 获取站点信息
    getsiteinfo(){
      var that=this
      this.$axios.get('/apis/api/webchat/getsitefromid/?siteid='+this.siteid,)
        .then(function (response) {
            var data=response.data
            if (data.code==200){
              that.user.avatar=data.data.kefuavatar
              that.user.displayName=data.data.kefuname
              that.user.id=that.siteid
            }else{
              console.log('getsiteinfo_error')
            }
        })
        .catch(function (error) {
          console.log('getsiteinfo_error')
        });
    },
    ChangeContact(contact){
      contact.unread=0
      this.clearUnread(contact.id)
    },
    // 获取联系人信息
    getcontacts(IMUI){
      var _that=this
      this.$axios({
          url:'/apis/api/webchat/getcontacts/',
          method:'get',
          headers:{Authorization:this.token || ''},
      })
        .then(function (response) {
           var listdata=response.data.data
           for (var i=0;i<listdata.length;i++){
            _that.contacts.push(listdata[i].id)
           }
           IMUI.initContacts(response.data.data);
        })
        .catch(function (error) {
          console.log('getcontacts_error')
        });
    },
    // 获取联系人信息
    changecontacts(IMUI){
      var _that=this
      this.$axios({
          url:'/apis/api/webchat/getcontacts/',
          method:'get',
          headers:{Authorization:this.token || ''},
      })
        .then(function (response) {
          var datalist=response.data.data
           for (var i=0;i<datalist.length;i++){
            if (_that.contacts.includes(datalist[i].id)){
              IMUI.updateContact(datalist[i]);
            }else{
              IMUI.appendContact(datalist[i]);
              _that.contacts.push(datalist[i].id)
            }
            
           }
        })
        .catch(function (error) {
          console.log('getcontacts_error')
        });
    },
    // 初始化websocket链接
    initWebsocket(){
      this.webs=new WebSocket(this.socketurl)
      this.webs.onopen=this.websockeopen
      this.webs.onerror=this.websockeerror
      this.webs.onmessage=this.onmessage
      this.webs.onclose=this.onclose
    },
    //清除未读
    clearUnread(userid){
      this.$axios({
          url:'/apis/api/webchat/unreadclear/?userid='+userid,
          method:'get',
          headers:{Authorization:this.token || ''},
      })
        .then(function (response) {
          
        })
        .catch(function (error) {
          console.log('getcontacts_error')
        });
    },
    // 获取信息
    onmessage(msg){
      const { IMUI } = this.$refs;
      var currentcontact=IMUI.getCurrentContact()
      if (currentcontact!={}){
        this.clearUnread(currentcontact.id)
      }
      var data=JSON.parse(msg.data)
      if(!this.concactids.includes(data.fromUser.id)){
        var contact= {
        id: data.fromUser.id,
        displayName: data.fromUser.displayName,
        avatar: data.fromUser.avatar,
        index: '游客',
        lastContent: IMUI.lastContentRender(data),
        unread: 0,
        }
        this.concactids.push(data.fromUser.id)
        IMUI.appendContact(contact)
      }
      if (data.fromUser.id==this.user.id){
        data['fromUser']=this.user
      }
      IMUI.appendMessage(data,true);
    },
    websockeopen(msg){

    },
    websockeerror(msg){
      console.log('WebSocket 发生错误，尝试重连...');
      this.reconnect()
    },
    onclose(msg){
      console.log('WebSocket 连接断开，尝试重连...');
      this.reconnect()
    },
    reconnect(){
      var that=this
      let connentWork=setTimeout(function(){
        console.log('正在重连')
        if (that.webs.readyState==WebSocket.CLOSED){
          that.initWebsocket()
          clearTimeout(connentWork)
        }
      },3000)
    },
    getchathis(userid,next){
      var _that=this
      var _page=1
      if (userid in _that.contactspage){
          _page=_that.contactspage.userid
      }
      else{
         _that.contactspage[userid]=1
      }
      this.$axios({
        method:'get',
        url:"/apis/api/webchat/getchathissite/?page="+_that.contactspage[userid]+'&userid='+userid,
        headers:{Authorization:this.token || ''},
      })
          .then(function (response) {
              var data=response.data
              if (data.code==200){
                var messages =data.result.list
                if (messages.length==0){
                  next(messages,true);
                  return
                }
                //将第二个参数设为true，表示已到末尾，聊天窗口顶部会显示“暂无更多消息”，不然会一直转圈。
                if (data.result.page==data.result.pageCount){
                  next(messages,true);
                }else{
                  next(messages,false);
                }
                _that.contactspage[userid]+=1
                
              }else{
                console.log('getchathis_error')
              }
          })
          .catch(function (error) {
            console.log('getchathis_error')
          });
    },
    // 从后端获取数据
    handlePullMessages(contact, next) {
      var _that=this
      contact.unread=0
      var userid=contact.id
      setTimeout(function(){
        _that.getchathis(userid,next)
      },500)
    },
    // 发送数据
    handleSend(message, next, file) {
      message.content=message.content.replace(/[\r\n]$/, '');
      this.webs.send(JSON.stringify(message))
      //执行到next消息会停止转圈，如果接口调用失败，可以修改消息的状态 next({status:'failed'});
      next();
    },
    sendKey(e){
      if (e.keyCode == 13 && e.ctrlKey==false && e.shiftKey==false && e.altKey==false){
        return true
      }else{
        return false
      }
    },
    downfile(messages){
      window.open(messages.content)
    },
    messageclick(event,key,message,instance){
      if (message.type=='image'){
        this.srcList=[message.content]
        document.getElementById('elimg').click()
      }
    }
  }
}
</script>
<style>
.lemon-message__content img{
  height:auto !important;
  min-height:100px !important;
  max-height: 300px !important;
}
.lemon-message__content video{
  min-height:100px;
  max-height: 300px;
}
body{
  margin:0px !important
}
.image-with-online {
  position: relative;
  display: inline-block;
}
 
.image-with-online::after {
  content: '';
  position: absolute;
  top: 30px; /* 绿点的垂直位置 */
  left: 30px; /* 绿点的水平位置 */
  width: 10px; /* 绿点的宽度 */
  height: 10px; /* 绿点的高度 */
  background-color: rgb(6, 250, 6); /* 绿点的颜色 */
  border-radius: 50%; /* 绿点是圆形的 */
}
.image-with-offline {
  position: relative;
  display: inline-block;
}
 
.image-with-offline::after {
  content: '';
  position: absolute;
  top: 30px; /* 绿点的垂直位置 */
  left: 30px; /* 绿点的水平位置 */
  width: 10px; /* 绿点的宽度 */
  height: 10px; /* 绿点的高度 */
  background-color: rgb(250, 6, 10); /* 绿点的颜色 */
  border-radius: 50%; /* 绿点是圆形的 */
}
.lemon-menu{
  background-color: #fff !important;
}
.lemon-menu__item{
  color:#1777ff !important
}
</style>