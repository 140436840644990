<script>
import {h} from 'vue'
export default {
  name: "lemonMessageText",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-text"
        props={{ ...this.$attrs }}
        scopedSlots={{ 
          content: props => {
            {/* const content = this.IMUI.emojiNameToImage(props.content); */}
            return h("div", {
              domProps: {
                  innerHTML: props.content // 这里是要渲染的数据
              }
          })
          }
        }}
      />
    );
  }
};
</script>
<style lang="stylus">

</style> 
