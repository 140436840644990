<script>
export default {
  name: "lemonMessageLive",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-voice"
        props={{ ...this.$attrs }}
        scopedSlots={{ 
          content: props => {
            {/* const content = this.IMUI.emojiNameToImage(props.content); */}
            return <div  id={props.id} style='width:300px;height:200px;background-color:black'></div>;
          }
        }}
      />
    );
  },
  mounted(){
    var message=this.$attrs.message
    var videoObject = {
            container: '#'+message.id,//“#”代表容器的ID，“.”或“”代表容器的class,
            plug:'hls.js',//设置使用hls插件
            controls:false,
            m3u8autoLoad:false,
            timeScheduleAdjust:0,
            // barHideTime:999990,
            video:message.content
    };
    var player=new ckplayer(videoObject );
    player.addListener('play',function(){
      setTimeout(function(){player.pause()},200)
      
    })
    player.bar(false)
    // player.remove()
  }
};
</script>
<style lang="stylus">

</style> 
