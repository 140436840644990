<template>
    <div>
        <el-dialog
            title="请选择你希望翻译的文档格式"
            :visible='transofficeVisible'
            :before-close="handleClose"
            width="50%">
            <el-select ref='fromselect' v-model="transofficevalue.fromla" placeholder="请选择">
                <el-option 
                @click.native="fromclick(item.label)"
                v-for="item in officeoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            ->翻译为:
            <el-select v-model="transofficevalue.tola" placeholder="请选择">
                <el-option 
                @click.native="toclick(item.label)"
                v-for="item in officeoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <el-button  type="success" @click="transofficework">确认</el-button>
             <!-- {{message}} -->
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'TransOffice',
    props:{'message':{
      type: Boolean,
      default: true
    },'userid':{
      type:String
    }},
    computed:{
        transofficeVisible:{
            get(){
                return this.message
            },
            set(newValue){
                this.$emit('update:value',newValue)
            }
        }
    },
    data(){
        return {
        
        
        geshitag:false,
        // geshiVisible:true,
        transofficevalue:{
          fromla:'zh',
          fromlabel:'中文',
          tola:'en',
          tolabel:'英文',
        },
        officeoptions: [
            {
            value: 'zh',
            label: '中文',
            },
            {
            value: 'en',
            label: '英语',
            },
            {
            value: 'ko',
            label: '韩语',
            },
            {
            value: 'ja',
            label: '日语',
            },
            {
            value: 'fr',
            label: '法语',
            },
            {
            value: 'es',
            label: '西班牙语',
            },
            {
            value: 'pt',
            label: '葡萄牙语',
            },
            {
            value: 'it',
            label: '意大利语',
            },
            {
            value: 'ru',
            label: '俄语',
            },
            {
            value: 'ar',
            label: '阿拉伯语',
            },
            {
            value: 'tr',
            label: '土耳其语',
            },
            {
            value: 'id',
            label: '印度尼西亚语',
            },
             {
            value: 'vi',
            label: '越南语',
            },
             {
            value: 'th',
            label: '泰语',
            },
             {
            value: 'de',
            label: '德语',
            },
             {
            value: 'pl',
            label: '波兰语',
            },
             {
            value: 'zh-tw',
            label: '中文繁体',
            },
        ],
            }
        },
        methods:{
            getCookie (name) {
              var value = '; ' + document.cookie
              var parts = value.split('; ' + name + '=')
              if (parts.length === 2) return parts.pop().split(';').shift()
            },
            fromclick(label){
                this.transofficevalue.fromlabel=label
            },
            toclick(label){
                this.transofficevalue.tolabel=label
            },
             handleClose(done) {
               this.$emit('close-event');
            },
            transofficework(){
                var _that=this
                this.$axios({
                    method:'post',
                    headers:{'X-CSRFToken':_that.getCookie('csrftoken')},
                    url:'/apis/api/v1/transofficeupload/',
                    data:{fakeid:this.userid,fromla:this.transofficevalue.fromla,tola:this.transofficevalue.tola}
                }).then(function (response) {
                            var data=response.data
                            console.log(data)
                            _that.$emit('child-event', _that.transofficevalue);
                        })
                        .catch(function (error) {
                        console.log(error)
                        });
                
            },
            
        }
}
</script>