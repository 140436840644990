<template>
    <div>
        <el-dialog
            title="请选择音色"
            :visible="peiyinVisible"
            :before-close="handleClose"
            width="50%">
          <el-row>
              <el-col :span="7" :offset="1">
                <el-select v-model="peiyintype" placeholder="请选择" @change="typechange">
                <el-option
                  v-for="item in peiyinoptins"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              </el-col>
              <el-col :span="7" :offset="1">
                <div class="block">
                <span class="demonstration">音量选择</span>
                <el-slider
                  v-model="sound"
                  :step="10"
                  :min="70"
                  :max="140"
                  height="200px">
                </el-slider>
              </div>
              </el-col>
              <el-col :span="7" :offset="1">
                <div class="block">
                <span class="demonstration">速度选择</span>
                <el-slider
                  v-model="speed"
                  :step="10"
                  :min="70"
                  :max="140"
                  :format-tooltip="formatTooltip"
                  height="200px">
                </el-slider>
              </div>
              </el-col>
          </el-row>
          <el-row style="height: 405px;overflow: scroll">
            <el-col :xs="23" :sm="11" :md="11" :lg="5" :xl="5" :offset="1" v-for="item in peiyinitemoptins.child" :key="item.id" style="margin-top: 10px;" >
              <div style="background-color: #f5f5f5;border-radius: 4px;height: 204px;text-align: center;padding-top: 28px;">
              <img style="height: 72px;width: 72px; border-radius: 50%;" :src='item.thumb_url'/>
              <br>
              <div style="margin-top: 10px;">
                <a style="font-weight:500;font-size: 16px;">{{ item.name }}</a>
              <br>
              <a>{{ item.description }}</a>
              </div>

              <el-row>
                <el-col :span="24">
                  <el-button style="margin-top: 5px;" size="mini" round type="primary" @click="usespeak(item)">使用音色</el-button>
                </el-col>
                <!-- <el-col :span="24">
                  <el-button style="margin-top: 5px;" size="mini" round type="success" @click="shiting(item)">试听音色</el-button>
                </el-col> -->
              </el-row>
              
              

            </div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple"></div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
          </el-row>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'peiYin',
    props:{'message':{
      type: Boolean,
      default: true
    },'userid':{
      type:String
    }},
    computed:{
        peiyinVisible:{
            get(){
                return this.message
            },
            set(newValue){
                this.$emit('update:value',newValue)
            }
        }
    },
    created(){
      this.getdata()
    },
    data(){
        return {
            zhibotag:false,
            sound:100,
            speed:100,
            peiyintype:'全部',
            peiyinitemoptins:{},
            peiyinoptins:[],
          }
        },
        methods:{
            getCookie (name) {
              var value = '; ' + document.cookie
              var parts = value.split('; ' + name + '=')
              if (parts.length === 2) return parts.pop().split(';').shift()
            },
            handleClose(done) {
               this.$emit('close-event');
            },
            getdata(){
              var _that=this
              this.$axios.get('/apis/api/v1/peiyinlist/')
                .then(function (response) {
                    var data=response.data
                    console.log(data)
                    _that.peiyinoptins=data.data
                    _that.peiyinitemoptins= _that.peiyinoptins[0]
                })
                .catch(function (error) {
                  console.log('diantai_error')
                });
            },
            shiting(data){
              console.log(this.userid)
            },
            usespeak(data){
              var _that=this
              this.$axios({
                    method:'post',
                    headers:{'X-CSRFToken':_that.getCookie('csrftoken')},
                    url:'/apis/api/v1/peiyinupload/',
                    data:{fakeid:this.userid,speakid:data.id,sound:this.sound,speed:this.speed}
                }).then(function (response) {
                    var data=response.data
                    console.log(data)
                    _that.$emit('child-event')
                })
                .catch(function (error) {
                  console.log('peiyinuploaderror')
                });
            },
            formatTooltip(val) {
              return val/100;
            },
            typechange(value){
              console.log(this.peiyinoptins)
              for(var i=0;i<this.peiyinoptins.length;i++){
                  if(this.peiyinoptins[i].name==value){
                  this.peiyinitemoptins=this.peiyinoptins[i]
                }
              }
            }
            
        }
}
</script>