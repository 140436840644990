<template>
    <div>
        <el-dialog
            title="请选择电台"
            :visible="diantaiVisible"
            :before-close="handleClose"
            width="50%">
            <el-tabs v-model="activeName">
              <el-tab-pane label="城市电台" name="city">
                <el-row>
                    <el-col :span="7" :offset="1">
                      <el-select v-model="cityselect" placeholder="请选择" @change="citychange">
                      <el-option
                        v-for="item in citydiantai"
                        :key="item.title"
                        :label="item.title"
                        :value="item.title">
                      </el-option>
                    </el-select>
                    </el-col>
                </el-row>
                <el-row style="height: 405px;overflow: scroll">
                  <el-col :xs="23" :sm="11" :md="11" :lg="5" :xl="5" :offset="1" v-for="item in cityoptions.child" :key="item.id" style="margin-top: 10px;" >
                    <div style="background-color: #f5f5f5;border-radius: 4px;height: 175px;text-align: center;padding-top: 28px;">
                    <img style="height: 72px;width: 72px; border-radius: 50%;" :src='item.img'/>
                    <br>
                    <div style="margin-top: 10px;">
                      <a style="font-weight:500;font-size: 16px;">{{ item.title }}</a>
                    <br>
                    </div>
                    <el-row>
                      <el-col :span="24">
                        <el-button style="margin-top: 5px;" size="mini" round type="primary" @click="listendt(item)">收听电台</el-button>
                      </el-col>
                    </el-row>
                  </div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple"></div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="网络电台" name="net">
                <el-row style="height: 405px;overflow: scroll">
                  <el-col :xs="23" :sm="11" :md="11" :lg="5" :xl="5" :offset="1" v-for="item in netdiantai.child" :key="item.id" style="margin-top: 10px;" >
                    <div style="background-color: #f5f5f5;border-radius: 4px;height: 175px;text-align: center;padding-top: 28px;">
                    <img style="height: 72px;width: 72px; border-radius: 50%;" :src='item.img'/>
                    <br>
                    <div style="margin-top: 10px;">
                      <a style="font-weight:500;font-size: 16px;">{{ item.title }}</a>
                    <br>
                    </div>
                    <el-row>
                      <el-col :span="24">
                        <el-button style="margin-top: 5px;" size="mini" round type="primary" @click="listendt(item)">收听电台</el-button>
                      </el-col>
                    </el-row>
                  </div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple"></div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="分类电台" name="etype">
                <el-row>
                    <el-col :span="7" :offset="1">
                      <el-select v-model="typeselect" placeholder="请选择" @change="typechange">
                      <el-option
                        v-for="item in typediantai"
                        :key="item.title"
                        :label="item.title"
                        :value="item.title">
                      </el-option>
                    </el-select>
                    </el-col>
                </el-row>
                <el-row style="height: 405px;overflow: scroll">
                  <el-col :xs="23" :sm="11" :md="11" :lg="5" :xl="5" :offset="1" v-for="item in typeoptions.child" :key="item.id" style="margin-top: 10px;" >
                    <div style="background-color: #f5f5f5;border-radius: 4px;height: 175px;text-align: center;padding-top: 28px;">
                    <img style="height: 72px;width: 72px; border-radius: 50%;" :src='item.img'/>
                    <br>
                    <div style="margin-top: 10px;">
                      <a style="font-weight:500;font-size: 16px;">{{ item.title }}</a>
                    <br>
                    </div>
                    <el-row>
                      <el-col :span="24">
                        <el-button style="margin-top: 5px;" size="mini" round type="primary" @click="listendt(item)">收听电台</el-button>
                      </el-col>
                    </el-row>
                  </div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple"></div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'dianTai',
    props:{'message':{
      type: Boolean,
      default: true
    },'userid':{
      type:String
    }},
    computed:{
        diantaiVisible:{
            get(){
                return this.message
            },
            set(newValue){
                this.$emit('update:value',newValue)
            }
        }
    },
    data(){
        return {
            activeName:'city',
            citydiantai:[],
            cityselect:'北京',
            cityoptions:[],
            netdiantai:[],
            typediantai:[],
            typeselect:'资讯台',
            typeoptions:[],
          }
        },
        created(){
          this.getdata()
        },
        methods:{
            getCookie (name) {
              var value = '; ' + document.cookie
              var parts = value.split('; ' + name + '=')
              if (parts.length === 2) return parts.pop().split(';').shift()
            },
            handleClose(done) {
               this.$emit('close-event');
            },
            listendt(data){
              var _that=this
              this.$axios({
                method:'post',
                headers:{'X-CSRFToken':_that.getCookie('csrftoken')},
                url:'/apis/api/v1/diantaiupload/',
                data:{fakeid:this.userid,_type:this.activeName,id:data.id}
              }).then(function (response) {
                    var data=response.data
                    console.log(data)
                    _that.$emit('child-event')
                })
                .catch(function (error) {
                  console.log('peiyinuploaderror')
                });
            },
            getdata(){
              var _that=this
              this.$axios.get('/apis/api/v1/diantailist/')
                .then(function (response) {
                    var data=response.data
                    _that.citydiantai=data.data.citydata
                    _that.cityoptions= _that.citydiantai[0]
                    _that.netdiantai=data.data.netdata
                    _that.typediantai=data.data.typedata
                    _that.typeoptions= _that.typediantai[0]
                })
                .catch(function (error) {
                  console.log('diantai_error')
                });
            },
            citychange(value){
              console.log(this.cityoptions)
              for(var i=0;i<this.citydiantai.length;i++){
                  if(this.citydiantai[i].title==value){
                  this.cityoptions=this.citydiantai[i]
                }
              }
            },
            typechange(value){
              console.log(this.typeoptions)
              for(var i=0;i<this.typediantai.length;i++){
                  if(this.typediantai[i].title==value){
                  this.typeoptions=this.typediantai[i]
                }
              }
            }
            
        }
}
</script>